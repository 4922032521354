'use client';

import * as Sentry from '@sentry/nextjs';
import { getSessionId } from '@debt-relief/session-id';
import {
  canUseDom,
  getAidFromQuery,
  getClickIdFromQuery,
} from '@debt-relief/utils';
import { getBuildTag } from '@shared/utils/env';
import { analyticsClient } from '@shared/api/analytics';
import {
  type TAnalyticsSendBatchEventsQuery,
  type TAnalyticsEvent,
} from '@shared/api/analytics/types';
import {
  type TAnalyticsTrackQuery,
  type TAnalyticsTrackOptions,
} from './types';
import { DEFAULT_APP, DEFAULT_PROJECT, QUEUE_FLUSH_DELAY } from './constants';

interface TAnalyticsQueueItem {
  event: TAnalyticsEvent;
  options: TAnalyticsTrackOptions;
}

interface TAnalyticsGroupedEventsItem {
  events: TAnalyticsEvent[];
  query: TAnalyticsSendBatchEventsQuery;
}

let queue: TAnalyticsQueueItem[] = [];
let flushTimeoutId = 0;
let flushPromise: Promise<void> | undefined;

const buildGroupingKey = ({
  app = DEFAULT_APP,
  project = DEFAULT_PROJECT,
}: TAnalyticsTrackQuery = {}): string => `${app}_${project}`;

const buildRequestQuery = ({
  app = DEFAULT_APP,
  project = DEFAULT_PROJECT,
}: TAnalyticsTrackQuery = {}): TAnalyticsSendBatchEventsQuery => {
  const query: TAnalyticsSendBatchEventsQuery = {
    app,
    project,
    referrer: window.location.href,
    sessionId: getSessionId(),
    landingBuildTag: getBuildTag(),
    clickId: getClickIdFromQuery(),
  };

  const aid = getAidFromQuery();

  if (aid) {
    query.aid = aid;
  }

  return query;
};

const getGroupedEvents = (): Map<string, TAnalyticsGroupedEventsItem> =>
  queue.reduce<Map<string, TAnalyticsGroupedEventsItem>>(
    (acc, { event, options }) => {
      const key = buildGroupingKey(options.query);

      if (!acc.has(key)) {
        acc.set(key, {
          events: [],
          query: buildRequestQuery(options.query),
        });
      }

      acc.get(key)?.events.push(event);

      return acc;
    },
    new Map(),
  );

const clearQueue = (): void => {
  queue = [];
};

const cancelScheduledFlush = (): void => {
  window.clearTimeout(flushTimeoutId);
  flushTimeoutId = 0;
  flushPromise = undefined;
};

export const scheduleFlush = (): Promise<void> => {
  if (flushPromise === undefined) {
    flushPromise = new Promise<void>((resolve, reject) => {
      flushTimeoutId = window.setTimeout(() => {
        flushQueue().then(resolve, reject);
      }, QUEUE_FLUSH_DELAY);
    });
  }

  return flushPromise;
};

export const pushQueue = (item: TAnalyticsQueueItem): void => {
  queue.push(item);
};

export const flushQueue = async (): Promise<void> => {
  if (!canUseDom()) {
    return;
  }

  cancelScheduledFlush();

  const groupedEvents = getGroupedEvents();

  if (groupedEvents.size === 0) {
    return;
  }

  // flush events queue first to prevent race conditions
  clearQueue();

  const promises: Promise<unknown>[] = [];

  for (const { events, query } of groupedEvents.values()) {
    promises.push(
      analyticsClient
        .sendBatchEvents(events, {
          queries: query,
        })
        .catch((error: Error) => {
          Sentry.captureException(
            new Error(`Analytics sending failed with error: ${error.message}`),
          );
        }),
    );
  }

  await Promise.all(promises);
};
