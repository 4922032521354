import { URL_PARAMS_ALIASES } from './constants';

export const getUrlParamsWithoutAliases = (
  urlParams: URLSearchParams,
): URLSearchParams => {
  const paramsWithoutAliases = new URLSearchParams();

  urlParams.forEach((paramValue, paramKey) => {
    const origNameForAlias = Object.keys(URL_PARAMS_ALIASES).find((key) =>
      URL_PARAMS_ALIASES[key].includes(paramKey),
    );

    if (origNameForAlias) {
      paramsWithoutAliases.set(origNameForAlias, paramValue);
    } else {
      paramsWithoutAliases.set(paramKey, paramValue);
    }
  });

  return paramsWithoutAliases;
};
