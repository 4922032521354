'use client';
import { useContext } from 'react';
import { InactivityModalContext } from './inactivity-modal-context';

interface TInactivityModalState {
  opened: boolean;
  open: () => void;
  close: () => void;
  restart: () => void;
}

export const useInactivityModalState = (): TInactivityModalState => {
  const {
    isOpen: opened,
    setIsOpen,
    clearAndUnsubscribe,
    restartModalTimeout,
  } = useContext(InactivityModalContext);

  return {
    opened: Boolean(opened),
    open: () => setIsOpen?.(true),
    close: () => {
      setIsOpen?.(false);
      clearAndUnsubscribe?.();
    },
    restart: () => restartModalTimeout?.(),
  };
};
