import { stringifyUrlSearchParams } from '@debt-relief/utils';
import { type ZodiosPlugin } from '@zodios/core';
import { type AxiosPromise } from 'axios';

const REQUEST_HEADERS = { type: 'text/plain' };

export const pluginBeacon: ZodiosPlugin = {
  name: 'beacon',
  // eslint-disable-next-line @typescript-eslint/require-await -- no awaits required
  request: async (_, config) => ({
    ...config,
    adapter: (adapterConfig) => {
      const baseURL = adapterConfig.baseURL || '';

      if (!adapterConfig.url || adapterConfig.method !== 'post') {
        throw new Error('The beacon request is not configured correctly');
      }

      let url = `${baseURL}${adapterConfig.url}`;

      if (typeof adapterConfig.params === 'object') {
        url += `?${stringifyUrlSearchParams(
          adapterConfig.params as Record<string, unknown>,
        )}`;
      }

      const blobData = new Blob([adapterConfig.data], REQUEST_HEADERS);

      const beaconResponse = navigator.sendBeacon(url, blobData);

      // create fake response for beacon request
      const response: AxiosPromise<boolean> = Promise.resolve({
        data: beaconResponse,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: adapterConfig,
      });

      return response;
    },
  }),
};
