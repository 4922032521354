import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';
import { analyticsBaseUrl } from '../constants';
import { applyClientPlugin } from '../utils/apply-client-plugin';
import { pluginBeacon } from '../plugins/beacon';
import { analyticsApi } from './api';

export const analyticsClient = new Zodios(analyticsBaseUrl, analyticsApi);

applyClientPlugin(analyticsClient, pluginBeacon, {
  includeAliases: ['sendBatchEvents'],
});

export const analyticsHooks = new ZodiosHooks('analytics', analyticsClient);
