import { getUrlParamsWithoutAliases } from './get-url-params-without-aliases';

export const getUrlParam = (key: string): string | undefined => {
  if (typeof document === 'undefined') {
    return undefined;
  }

  const urlParams = new URLSearchParams(document.location.search);
  const paramsWithoutAliases = getUrlParamsWithoutAliases(urlParams);

  return paramsWithoutAliases.get(key) || undefined;
};
