import { makeApi } from '@zodios/core';
import z from 'zod';
import { analyticsProjectSchema, analyticsEventSchema } from './schemas';

export const analyticsApi = makeApi([
  {
    alias: 'sendBatchEvents',
    method: 'post',
    path: '/event/post-batch/',
    response: z.unknown(),
    parameters: [
      // Temporary unused query params: formBuildTag, formName, formType
      {
        name: 'app',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'project',
        type: 'Query',
        schema: analyticsProjectSchema,
      },
      {
        name: 'referrer',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'sessionId',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'landingBuildTag',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'clickId',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'aid',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'events',
        type: 'Body',
        schema: analyticsEventSchema.array(),
      },
    ],
  },
]);
