import z from 'zod';

export const analyticsProjectSchema = z.enum(['debt-relief']);

export const analyticsBaseEventSchema = z.object({
  triggeredAt: z.number().min(0),
});

export const visitEventSchema = analyticsBaseEventSchema.extend({
  type: z.literal('visit'),
  payload: z.object({}),
});

export const showInactivityModalEventSchema = analyticsBaseEventSchema.extend({
  type: z.literal('showInactivityModal'),
  payload: z.object({}),
});

export const goToFormEventSchema = analyticsBaseEventSchema.extend({
  type: z.literal('goToForm'),
  payload: z.object({
    referrer: z.enum(['header', 'instructions', 'modal', 'yes', 'no']),
  }),
});

export const clickToCallEventSchema = analyticsBaseEventSchema.extend({
  type: z.literal('clickToCall'),
  payload: z.object({
    referrer: z.enum(['header', 'inactivityDialog', 'main']),
  }),
});

export const analyticsEventSchema = z.union([
  visitEventSchema,
  goToFormEventSchema,
  clickToCallEventSchema,
  showInactivityModalEventSchema,
]);
