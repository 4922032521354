import {
  type ZodiosEndpointDefinitions,
  type ZodiosInstance,
  type ZodiosPlugin,
} from '@zodios/core';
import { type Aliases } from '@zodios/core/lib/zodios.types';

interface TUsePluginTokenOptions<Api extends ZodiosEndpointDefinitions> {
  includeAliases?: Aliases<Api>[];
  excludeAliases?: Aliases<Api>[];
}

export const applyClientPlugin = <Api extends ZodiosEndpointDefinitions>(
  client: ZodiosInstance<Api>,
  plugin: ZodiosPlugin,
  options: TUsePluginTokenOptions<Api> = {},
): void => {
  const { includeAliases, excludeAliases } = options;

  client.api.forEach((endpoint) => {
    const alias = endpoint.alias as Aliases<Api>;

    const included =
      !Array.isArray(includeAliases) || includeAliases.includes(alias);
    const excluded =
      Array.isArray(excludeAliases) && excludeAliases.includes(alias);

    if (included && !excluded) {
      client.use(alias, plugin);
    }
  });
};
