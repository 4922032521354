export const stringifyUrlSearchParams = (
  params: Record<string, unknown>,
): string => {
  const urlParams = Object.fromEntries(
    Object.keys(params).reduce<[string, string][]>((memo, key) => {
      const value = params[key];

      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        memo.push([key, String(value)]);
      }

      return memo;
    }, []),
  );

  const searchParams = new URLSearchParams(urlParams);

  return searchParams.toString();
};
