import { type TUtmParams } from './types';
import { getUrlParam } from './get-url-param';

export const getUtmParamsFromQuery = (): TUtmParams => {
  const utmSource = getUrlParam('utm_source');
  const utmMedium = getUrlParam('utm_medium');
  const utmContent = getUrlParam('utm_content');
  const utmCampaign = getUrlParam('utm_campaign');

  return {
    ...(utmSource ? { utmSource } : {}),
    ...(utmMedium ? { utmMedium } : {}),
    ...(utmContent ? { utmContent } : {}),
    ...(utmCampaign ? { utmCampaign } : {}),
  };
};
