'use client';
import type { PropsWithChildren, FC } from 'react';
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { unsubscribe } from '@entities/inactivity-modal/lib/unsubscribe';
import { subscribe } from '@entities/inactivity-modal/lib/subscribe';
import type { TInactivityModalContextPayload } from '../lib/inactivity-modal-context';
import { InactivityModalContext } from '../lib/inactivity-modal-context';
import { DEFAULT_THRESHOLD } from '../lib/constants';

export const InactivityModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const timeoutId = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const restartModalTimeout = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    subscribe(restartModalTimeout);

    timeoutId.current = window.setTimeout(() => {
      unsubscribe(restartModalTimeout);
      setIsOpen(true);
    }, DEFAULT_THRESHOLD);
  }, []);

  const clearAndUnsubscribe = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    unsubscribe(restartModalTimeout);
  }, [restartModalTimeout]);

  useEffect(() => {
    clearAndUnsubscribe();
    restartModalTimeout();

    return clearAndUnsubscribe;
  }, [clearAndUnsubscribe, restartModalTimeout]);

  const defaultValue: TInactivityModalContextPayload = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      clearAndUnsubscribe,
      restartModalTimeout,
    }),
    [clearAndUnsubscribe, isOpen, restartModalTimeout],
  );

  return (
    <InactivityModalContext.Provider value={defaultValue}>
      {children}
    </InactivityModalContext.Provider>
  );
};
