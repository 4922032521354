import { canUseDom } from '../dom';
import { type TGtagEventOptions } from './types';

let globals: Omit<TGtagEventOptions, 'event'> = {};

if (canUseDom()) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Force dataLayer in window
  window.dataLayer = window.dataLayer || [];
}

export const setGtagGlobals = (
  options: Omit<TGtagEventOptions, 'event'>,
): void => {
  globals = { ...globals, ...options };
};

export const gtag = (options: TGtagEventOptions): void => {
  window.dataLayer.push({ ...globals, ...options });
};
