export const DEFAULT_THRESHOLD = 7_000;

export const INACTIVITY_EVENT_NAMES = [
  'mousemove',
  'mousedown',
  'touchstart',
  'click',
  'keydown',
  'scroll',
];
