import { canUseDom } from '@debt-relief/utils';
import { flushQueue } from './events-queue';

const handleVisibilityChange = (): void => {
  if (document.visibilityState === 'hidden') {
    void flushQueue();
  }
};

const handleBeforeUnload = (): void => {
  void flushQueue();
};

export const bindAnalyticsWatcher = (): void => {
  if (!canUseDom()) {
    return;
  }

  document.addEventListener('visibilitychange', handleVisibilityChange);
  window.addEventListener('beforeunload', handleBeforeUnload);
};

export const unbindAnalyticsWatcher = (): void => {
  if (!canUseDom()) {
    return;
  }

  document.removeEventListener('visibilitychange', handleVisibilityChange);
  window.removeEventListener('beforeunload', handleBeforeUnload);
};
