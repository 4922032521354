import { invariant } from '../invariant';
import { type Nullable, nonNullish } from '../nullish';

// Use function declaration here to supress TS error:
// Assertions require every name in the call target to be declared with an explicit type annotation.
export function assertEnv<T>(value: Nullable<T>): asserts value {
  invariant(
    nonNullish(value),
    `environment variable is required, got ${String(value)}`,
  );
}
