import { getEnv } from '@debt-relief/utils';

export const apiBaseUrl = getEnv(process.env.NEXT_PUBLIC_API_BASE_URL);
export const formVersionBaseUrl = getEnv(process.env.NEXT_PUBLIC_FORM_BASE_URL);
export const analyticsBaseUrl = getEnv(
  process.env.NEXT_PUBLIC_ANALYTICS_BASE_URL,
);

export const ipInfoBaseUrl = getEnv(process.env.NEXT_PUBLIC_IP_INFO_BASE_URL);

export const moneyForOfferWallUrlLessThen10000 = getEnv(
  process.env.NEXT_PUBLIC_MONEY_FOR_OFFER_WALL_LESS_THEN_10_000_URL,
);

export const moneyForOfferWallUrl = getEnv(
  process.env.NEXT_PUBLIC_MONEY_FOR_OFFER_WALL_URL,
);

export const JGUrl = getEnv(process.env.NEXT_PUBLIC_JG_URL);
