import Cookies from 'js-cookie';

export const getFilteredCookies = (
  filter?: (name: string, value: string) => boolean,
): Record<string, string> =>
  Object.entries(Cookies.get()).reduce<Record<string, string>>(
    (acc, [name, value]) => {
      if (!filter || filter(name, value)) {
        acc[name] = value;
      }

      return acc;
    },
    {},
  );
