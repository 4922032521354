'use client';

import { canUseDom } from '@debt-relief/utils';
import { type TAnalyticsEvent } from '@shared/api/analytics/types';
import { type TAnalyticsTrackEventMethod } from './types';
import { flushQueue, pushQueue, scheduleFlush } from './events-queue';

export const trackEvent: TAnalyticsTrackEventMethod = async (
  event,
  options = {},
) => {
  if (!canUseDom()) {
    return;
  }

  pushQueue({
    event: {
      ...event,
      triggeredAt: Date.now(),
    } as TAnalyticsEvent,
    options,
  });

  if (options.instant) {
    return flushQueue();
  }

  return scheduleFlush();
};
