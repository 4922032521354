import { getEnv, invariant, isKnownSetValue } from '@debt-relief/utils';
import { type TBuildLocation } from './types';

const BUILD_LOCATIONS = new Set<TBuildLocation>([
  'production',
  'staging',
  'development',
]);

// Use function declaration here to supress TS error:
// Assertions require every name in the call target to be declared with an explicit type annotation.
export function assertBuildLocation(
  value: string,
): asserts value is TBuildLocation {
  invariant(
    isKnownSetValue(BUILD_LOCATIONS, value),
    `unknown BUILD_LOCATION: ${String(value)}`,
  );
}

export const castBuildLocation = (value: string): TBuildLocation => {
  assertBuildLocation(value);

  return value;
};

export const getBuildLocation = () =>
  getEnv(process.env.BUILD_LOCATION, castBuildLocation);
