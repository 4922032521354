const prefix = 'Invariant failed: ';

// Use function declaration here to supress TS error:
// Assertions require every name in the call target to be declared with an explicit type annotation.
export function invariant(
  condition: unknown,
  message: string,
): asserts condition {
  if (condition) {
    return;
  }

  throw new Error(`${prefix}${message}`);
}
