'use client';

import { useMemo } from 'react';
import {
  type TAnalyticsTrackEventMethod,
  type TUseAnalyticsOptions,
  type TAnalytics,
} from './types';
import { trackEvent as trackEventBase } from './track-event';
import { sendEvents } from './send-events';

export const useAnalytics = ({
  query,
}: TUseAnalyticsOptions = {}): TAnalytics =>
  useMemo(() => {
    const trackEvent: TAnalyticsTrackEventMethod = (
      event,
      { query: methodQuery, ...options } = {},
    ) =>
      trackEventBase(event, {
        query: { ...query, ...methodQuery },
        ...options,
      });

    return {
      trackEvent,
      sendEvents,
    };
  }, [query]);
