import { createContext } from 'react';

export interface TInactivityModalContextPayload {
  isOpen?: boolean;
  setIsOpen?: (openState: boolean) => void;
  clearAndUnsubscribe?: () => void;
  restartModalTimeout?: () => void;
}
export const InactivityModalContext =
  createContext<TInactivityModalContextPayload>({});
