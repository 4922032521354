import { isNullish } from '../nullish';
import { getStorage } from './get-storage';

export const loadStorageState = <S>(
  key: string,
  persistent?: boolean,
): S | undefined => {
  try {
    const state = getStorage(persistent)?.getItem(key);

    if (isNullish(state)) {
      return undefined;
    }

    return JSON.parse(state) as S;
  } catch (error) {
    return undefined;
  }
};
