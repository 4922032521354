import { type TCurrencyUnit, type TCurrencyDecimalsMode } from './types';

const THOUSANDS_REG_EXP = /\B(?=(?:\d{3})+(?!\d))/g;

const unitSigns: Partial<Record<TCurrencyUnit, string>> = {
  'us-dollar': '$',
  'us-cent': '$',
};

const formatParts = (
  integer: string | number,
  decimals: string | number,
  decimalsMode: TCurrencyDecimalsMode,
): string => {
  const integerStr = String(integer).replace(THOUSANDS_REG_EXP, ',');

  const parts = [integerStr];

  // pad decimal part to 2 digits
  const decimalsStr = String(decimals).padStart(2, '0');

  if (
    decimalsMode === 'always' ||
    (decimalsMode === 'if-needed' && decimalsStr !== '00')
  ) {
    parts.push(decimalsStr);
  }

  return parts.join('.');
};

const formatBaseValue = (
  value: number,
  decimalsMode: TCurrencyDecimalsMode,
): string => {
  const [integer, decimals] = Math.abs(value).toFixed(2).split('.');

  return formatParts(integer, decimals, decimalsMode);
};

const formatCentValue = (
  value: number,
  decimalsMode: TCurrencyDecimalsMode,
): string => {
  const absValue = Math.floor(Math.abs(value));

  const integer = Math.floor(absValue / 100);
  const decimals = absValue % 100;

  return formatParts(integer, decimals, decimalsMode);
};

const valueFormatters: Record<
  TCurrencyUnit,
  (value: number, decimalsMode: TCurrencyDecimalsMode) => string
> = {
  'us-dollar': formatBaseValue,
  'us-cent': formatCentValue,
};

export const formatCurrency = (
  unit: TCurrencyUnit,
  value: number,
  decimalsMode: TCurrencyDecimalsMode = 'always',
): string => {
  const valueFormatter = valueFormatters[unit];

  const negativeSign = value < 0 ? '-' : '';
  const unitSign = unitSigns[unit] ?? '';

  return [negativeSign, unitSign, valueFormatter(value, decimalsMode)].join('');
};
