import { isPositiveInteger } from '../number';
import { getUrlParam } from './get-url-param';

export const getAidFromQuery = (): number | undefined => {
  const rawAid = getUrlParam('aid');

  if (rawAid === undefined) {
    return undefined;
  }

  const aid = Number.parseInt(rawAid, 10);

  return isPositiveInteger(aid) ? aid : undefined;
};
