import { getUrlParamsWithoutAliases } from './get-url-params-without-aliases';

export const getUrlParams = (): Record<string, string> => {
  if (typeof document === 'undefined') {
    return {};
  }

  const urlParams = new URLSearchParams(document.location.search);
  const paramsWithoutAliases = getUrlParamsWithoutAliases(urlParams);

  return Object.fromEntries(paramsWithoutAliases);
};
